import Vue from 'vue';
import Vuex from 'vuex';

import $http from './services/HttpClient';
import { $logger } from './services/Logger';
import $myPmBridge from './services/MypmBridge';

import $config from './config';
import isTokenValid from './utils/isTokenValid';
import routes from './routes';

Vue.use(Vuex);

export default new Vuex.Store({
  /**
   * Global states.
   */
  state: {
    /**
     * Actual ipxx access token of the user.
     */
    accessToken: null,
    /**
     * Actual Auth token of the logged in AD user.
     */
    authToken: null,
    /**
     * Actual error code from the last response.
     */
    error: null,
    /**
     * If a request is still in progress, loading is true.
     */
    loading: false,
    /**
     * The email address which the user used to successfully register a my-pm account.
     */
    registrationEmail: null,
    /**
     * User information received from the login of the external auth provider.
     */
    userInfo: null,
  },
  /**
   * Getters for state values.
   */
  getters: {
    getAccessToken: (state) => state.accessToken,
    getAuthToken: (state) => state.authToken,
    getError: (state) => state.error,
    getRegistrationEmail: (state) => state.registrationEmail,
    getUserInfo: (state) => state.userInfo,
    isLoading: (state) => state.loading,
    isLoggedIn: (state) => state.accessToken !== null,
  },
  /**
   * Synchronous setters to update or clear state values.
   */
  mutations: {
    /**
     * Delete token from store and remove from »Authorization« header.
     */
    clearAuthToken: (state) => {
      delete $http.defaults.headers.common.authorization;
      state.authToken = null;
    },
    clearAccessToken: (state) => {
      state.accessToken = null;
    },
    setAccessToken: (state, payload) => {
      state.accessToken = payload;
    },
    /**
     * Store and update the »Authorization« header.
     */
    setAuthToken: (state, payload) => {
      $http.defaults.headers.common.Authorization = `Bearer ${payload}`;
      state.authToken = payload;
    },
    setError: (state, payload) => {
      if (payload) {
        $logger.log(payload.type, payload.message);
      }
      state.error = payload?.message || null;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setRegistrationEmail: (state, payload) => {
      state.registrationEmail = payload;
    },
    setUserInfo: (state, payload) => {
      state.userInfo = payload;
    },
  },
  /**
   * Asynchronous setters and actions.
   */
  actions: {
    /**
     * Sets an external auth error as global error.
     * Truncate error string because it is taken from query parameter.
     */
    addAuthError: ({ commit }, payload) => {
      commit('setError', { type: 'auth error occurred:', message: payload.code.substr(0, 30) });
    },
    /**
     * Tries to login user and set the returned ipxx access token.
     */
    authorizeUser: ({ commit, dispatch }) => {
      commit('setError', null);
      commit('setRegistrationEmail', null);
      commit('setLoading', true);
      const postData = {};
      const config = {
        delayed: true,
      };
      $http.post(routes.account_authorize, postData, config)
        .then((response) => {
          const { data } = response;
          dispatch('validateAccessToken', data);
        }).catch((error) => {
          commit('setLoading', false);
          if (error.data && error.data.failureId != null) {
            commit('setError', { type: 'account authorize fail', message: error.data.failureId });
          } else {
            commit('setError', { type: 'account authorize error', message: $config.errors.internal_server_error });
          }
        });
    },
    /**
     * Azure login action calls the »validateAuthToken« action on success.
     */
    handleAzureLogin: ({ commit, dispatch }, payload) => {
      commit('clearAuthToken');
      commit('clearAccessToken');
      commit('setLoading', true);

      const { code } = payload;
      const state = payload.session_state;

      const config = {
        params: {
          code,
          state,
        },
      };
      $http.get(routes.handle_azure, config)
        .then((response) => {
          const { data } = response;
          if (data.personalInfo) {
            commit('setUserInfo', data.personalInfo);
          }
          dispatch('validateAuthToken', data);
        })
        .catch((error) => {
          commit('setLoading', false);
          if (error.data && error.data.failureId != null) {
            commit('setError', { type: 'handle azure fail', message: error.data.failureId });
          } else {
            commit('setError', { type: 'handle azure error', message: $config.errors.internal_server_error });
          }
        });
    },
    /**
     * Clears current error code and disables simulating errors with restify.
     */
    resetErrors: ({ commit }) => {
      commit('setError', null);
      return $http.get('/resetErrors');
    },
    /**
     * Starts the login process of the given external auth provider.
     * On success, redirects to the external auth provider Login page.
     * @param commit
     * @param payload
     */
    startLogin: ({ commit }, payload) => {
      if (payload.id === undefined || payload.id === null) {
        return;
      }
      const config = {
        params: {
          authProviderId: payload.id,
        },
      };

      if (payload.username) {
        config.params.username = payload.username;
      }

      if (payload.prompt) {
        config.params.prompt = payload.prompt;
      }

      $http.get(routes.start, config)
        .then((response) => {
          const { authorizeUrl } = response.data;
          window.location = authorizeUrl;
        })
        .catch((error) => {
          if (error.data && error.data.failureId != null) {
            commit('setError', { type: 'start login fail', message: error.data.failureId });
          } else {
            commit('setError', { type: 'start login error', message: $config.errors.internal_server_error });
          }
        });
    },
    /**
     * Starts the logout process of the given external auth provider.
     * On success, redirects to the external auth provider Logout page.
     * @param commit
     * @param payload
     */
    startLogout: ({ commit }, payload) => {
      if (payload.id === undefined || payload.id === null) {
        return;
      }
      const config = {
        params: {
          authProviderId: payload.id,
        },
      };

      $http.get(routes.start, config)
        .then((response) => {
          const { logoutUrl } = response.data;
          window.location = logoutUrl;
        })
        .catch((error) => {
          if (error.data && error.data.failureId != null) {
            commit('setError', { type: 'start logout fail', message: error.data.failureId });
          } else {
            commit('setError', { type: 'start logout error', message: $config.errors.internal_server_error });
          }
        });
    },
    /**
     * Finishes the user logout
     */
    finishLogout: async () => {
      await $myPmBridge.callLogoutFinished();
    },
    /**
     * Validates the ipxx access token and stores it. If the token is not valid, set error.
     */
    validateAccessToken: async ({ commit, getters }, payload) => {
      const { token } = payload.access;

      if (isTokenValid(token)) {
        commit('setAccessToken', token);
        $logger.info('Access Token is valid.');
        const success = await $myPmBridge.callSetTokens(
          getters.getAccessToken, getters.getAuthToken,
        );
        if (!success) {
          commit('setError', { type: 'bridge error', message: $config.bridgeErrors.unknown_function });
        }
      } else {
        commit('clearAccessToken');
        commit('setError', { type: 'token not valid error', message: $config.errors.unauthorized });
      }
      commit('setLoading', false);
    },
    /**
     * Validates the auth token and sets the token as the Bearer Token.
     */
    validateAuthToken: ({ commit, dispatch }, payload = {}) => {
      const token = payload.auth_token;

      if (isTokenValid(token)) {
        commit('setAuthToken', token);
        dispatch('authorizeUser');
      } else {
        commit('clearAuthToken');
        commit('setLoading', false);
        commit('setError', { type: 'token not valid error', message: $config.errors.unauthorized });
      }
    },
  },
});
