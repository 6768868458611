import routes from '@/routes';
import $http from './HttpClient';

const defaults = {
  enabled: true,
};

const Logger = {
  options: {},

  /**
   * Log only if loggin is enabled.
   *
   * @param {string} logLevel
   * @param {...any} logArguments
   */
  printLogMessage(logLevel, logArguments) {
    if (!this.options.enabled) {
      return;
    }

    const level = ['info', 'error', 'warn'].includes(logLevel)
      ? logLevel : 'log';

    if (process.env.VUE_APP_SERVER_LOGGING === 'true') {
      const message = `${level}: ${[...logArguments]}`;
      $http.post(routes.log, { message })
        .catch(() => {
          window.console[level](...logArguments);
        });
    } else {
      window.console[level](...logArguments);
    }
  },

  /**
   * Log an error.
   *
   * @param  {...any} args
   */
  error(...args) {
    this.printLogMessage('error', args);
  },

  /**
   * Log an info statement.
   *
   * @param  {...any} args
   */
  info(...args) {
    this.printLogMessage('info', args);
  },

  /**
   * Default log message.
   *
   * @param  {...any} args
   */
  log(...args) {
    this.printLogMessage('log', args);
  },

  /**
   * Log a warning.
   *
   * @param  {...any} args
   */
  warn(...args) {
    this.printLogMessage('warn', args);
  },

  /**
   * Override default options during initialization on a new Vue instance.
   *
   * @param {object} opts
   */
  setOptions(opts) {
    this.options = { ...defaults, ...opts };
    return this;
  },
};

export const $logger = Logger;

export default {
  /**
   * Install the Logger plugin on a Vue instance.
   *
   * @param {Vue} Vue The Vue instance object.
   * @param {object} opts Settings object to override default options.
   */
  install(Vue, opts) {
    Vue.prototype.$logger = Logger.setOptions(opts);
  },
};
