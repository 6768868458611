/**
 * Checks if the jwt token is valid.
 * @param {string} token
 */
export default (token) => {
  if (token && typeof token === 'string') {
    const data = JSON.parse(atob(token.split('.')[1]));
    const expires = data.exp * 1000;
    const now = Date.now();
    if (expires > now) {
      return true;
    }
    window.console.log('Token expired.');
  }
  return false;
};
