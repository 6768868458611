<template>
  <span
    class="ipxx-icon ipxx-icon--checkbox form-control"
    :class="{ disabled: isDisabled, checked: isChecked, 'alert-danger': hasError}"
    @click="toggle"
    @keyup.enter="toggle"
  >
    <fa-layers
      data-cy="ipxxCheckboxIcon"
    >
      <fa-icon
        :class="hasError ? 'ipxx-icon--checkbox error': 'text-ipxx-medium'"
        :icon="['far', 'square']"
      />
      <fa-icon
        v-show="isChecked"
        class="text-primary"
        icon="check"
        transform="shrink-7"
      />
    </fa-layers>
    <slot />
  </span>
</template>

<script>
export default {
  name: 'IpxxCheckbox',
  props: {
    /**
     * A flag to disable the checkbox.
     */
    disabled: Boolean,
    /**
     * A flag to style the checkbox in the error state.
     */
    error: Boolean,
    /**
     * Invert the model.
     */
    invert: Boolean,
    /**
     * The model directive value to create two-way data binding.
     */
    model: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isChecked: this.invert !== !!this.model,
      isDisabled: this.disabled,
      hasError: this.error,
    };
  },
  watch: {
    model(newVal) {
      this.isChecked = this.invert !== newVal;
    },
    disabled(newVal) {
      this.isDisabled = newVal;
    },
    error(newVal) {
      this.hasError = newVal;
    },
  },
  methods: {
    toggle() {
      if (this.isDisabled) {
        return;
      }

      this.isChecked = !this.isChecked;
      /**
       * Update event.
       *
       * @event update
       * @type {boolean}
       */
      this.$emit('update:model', this.invert !== this.isChecked);
    },
  },
};
</script>
