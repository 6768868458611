export default {
  corporate: {
    mypm_account: 'my-picturemaxx user account',
    support_email: 'support@picturemaxx.com',
  },
  errors: {
    access_denied: 'Access denied.<br>Please try again.',
    error_already_auth_assigned: 'The {account} has already been linked to a ‘{providerAccount}’.<br>The {account} no longer uses login credentials. Therefore you cannot reset the password.',
    error_external_auth_disabled: 'There is a problem with the configuration.<br>Please contact your administrator.',
    error_oidc: 'Error with OpenID Connect.<br>Please contact your administrator.',
    error_unknown_provider: 'The external authentication provider is unknown.<br>Please contact your administrator.',
    unknown_error: 'An unknown error has occurred.<br>Try again later.',
    unknown_qt_function: 'The login does not work with your current my-picturemaxx version.<br>Please contact your administrator. Error: Unknown function called.',
  },
  labels: {
    back: 'Back',
    cancel: 'Cancel',
    next: 'Continue',
    retry: 'Retry',
  },
  languages: {
    de: 'German',
    en: 'English',
  },
  providers: {
    azure: 'Microsoft',
    azure_account: 'Microsoft account',
    default: 'User',
    default_account: 'User account',
  },
  titles: {
    assign_account: 'with a my-picturemaxx user account',
    connect_account: 'Link your ‘{provider} account’',
    error: 'Error occurred.',
    execute_login: 'Login is in progress',
    login_error: 'An error has occurred',
    login_success: 'Login is successful',
    execute_logout: 'Logout is in progress',
    logout_error: 'An error has occurred',
    logout_success: 'Logout is successful',
    registration: 'Registration',
    reset_password: 'Reset password',
    verify_email: 'Verify email address',
  },
};
