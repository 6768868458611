import Vue from 'vue';
import VueI18n from 'vue-i18n';

import messages from './lang';

import countries from './countries';

// Add localized configs to the messages.
Object.keys(messages).forEach((langCode) => {
  messages[langCode].countries = countries[langCode]; // Add country fields.
});

Vue.use(VueI18n);

// Create and export a VueI18n instance with options.
export default new VueI18n({
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  messages,
  silentTranslationWarn: true,
});
