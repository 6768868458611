export default {
  corporate: {
    mypm_account: 'my-picturemaxx Benutzerkonto',
    support_email: 'support@picturemaxx.com',
  },
  errors: {
    access_denied: 'Zugriff verweigert.<br>Bitte versuchen Sie es erneut.',
    error_already_auth_assigned: 'Das {account} wurde bereits mit einem »{providerAccount}« verknüpft.<br>Das {account} verwendet keine Login-Anmeldedaten mehr. Sie können daher nicht mehr das Passwort zurücksetzen.',
    error_external_auth_disabled: 'Es ist ein Problem mit der Konfiguration aufgetreten.<br>Bitte wenden Sie sich an Ihren Administrator.',
    error_oidc: 'Fehler beim OpenID Connect.<br>Bitte wenden Sie sich an Ihren Administrator.',
    error_unknown_provider: 'Der externe Authentifizierungsanbieter ist unbekannt.<br>Bitte wenden Sie sich an Ihren Administrator.',
    unknown_error: 'Es ist ein unbekannter Fehler aufgetreten.<br>Bitte versuchen Sie es später erneut.',
    unknown_qt_function: 'Der Login funktioniert mit Ihrer aktuellen my-picturemaxx Version nicht.<br>Bitte kontaktieren Sie Ihren Administrator. Fehler: Unbekannte Funktion aufgerufen.',
  },
  labels: {
    back: 'Zurück',
    cancel: 'Abbrechen',
    next: 'Weiter',
    retry: 'Wiederholen',
  },
  languages: {
    de: 'Deutsch',
    en: 'Englisch',
  },
  providers: {
    azure: 'Microsoft',
    azure_account: 'Microsoft-Konto',
    default: 'Benutzer',
    default_account: 'Benutzer-Konto',
  },
  titles: {
    assign_account: 'mit einem my-picturemaxx Benutzerkonto',
    connect_account: 'Verknüpfen Ihres »{provider}-Kontos«',
    error: 'Ein Fehler ist aufgetreten.',
    execute_login: 'Anmeldung wird ausgeführt',
    login_error: 'Es ist ein Fehler aufgetreten',
    login_success: 'Login erfolgreich',
    execute_logout: 'Abmeldung wird ausgeführt',
    logout_error: 'Es ist ein Fehler aufgetreten',
    logout_success: 'Logout erfolgreich',
    registration: 'Registrierung',
    reset_password: 'Passwort zurücksetzen',
    verify_email: 'E-Mail-Adresse verifizieren',
  },
};
