const config = {};

/**
 * External authentication provider IDs.
 */
const providers = {
  azure: '1',
};

/**
 * Error codes from external authentication providers.
 */
const authErrors = {
  azure_access_denied: 'access_denied',
  azure_interaction_required: 'interaction_required',
  azure_invalid_resource: 'invalid_resource',
  azure_login_required: 'login_required',
  azure_server_error: 'server_error',
  azure_temp_unavailable: 'temporarily_unavailable',
  azure_unauthorized: 'unauthorized_client',
};

const bridgeErrors = {
  unknown_function: 'called_unknown_function',
};

/**
 * Error codes from API routes.
 */
const errors = {
  account_assign_external_id_already_assigned: '48ddd145-d472-4350-a008-a3237cb7543b',
  account_assign_invalid_credentials: 'ce61382b-f222-4a46-8746-04fade0a6eb5',
  account_assign_bound_to_other: '2182190c-6735-47cc-8622-fac7e8d2dd62',
  account_assign_already_assigned: '6f000606-9717-4d93-be27-3a8b4ae3f12e',
  account_assign_external_auth_disabled: '15972aa9-2b95-4e95-8242-7c41e36add07',
  account_assign_not_allowed_for_master: '04f94813-1ef9-49a6-a693-4ebe6177fb86',
  account_authorize_account_inactive: 'c4bdb39a-3300-464e-8a4b-d2f4004f13aa',
  account_authorize_assigned_error: '92e00b17-7e3b-490a-9525-c154c12818ab',
  account_authorize_email_not_verified: 'c344c454-004c-4338-811e-ae884ab42fb7',
  account_authorize_external_auth_disabled: 'd319ea35-1052-44b7-b058-f804c724395e',
  account_create_external_auth_disabled: '15dfc577-524b-492f-a782-cf7eff38a6fa',
  account_create_external_id_already_assigned: '5c96f037-31e0-4cd2-b09c-2a8b36f5341b',
  account_email_save_already_verified: '205b75ec-6882-4886-a2a6-0a41d955fa00',
  account_email_save_external_auth_disabled: 'd9059fb1-49a9-4fb7-b227-fe0c2ab652e0',
  account_email_verify_account_not_available: '642c5344-7bdd-40ae-9c65-1f767eef1808',
  account_email_verify_external_auth_disabled: '4d88913c-36f3-49a7-992b-f6f7aefaec01',
  account_email_verify_send_failed: '4e710d12-c6ac-4018-9e53-c0c8a089f8dc',
  account_reset_password_invalid: 'faca276e-a1a8-4029-9a94-f1a23eccf895',
  account_reset_password_already_auth_assigned: 'a9bc9ace-fa23-4935-a6e3-536dec42c1bd',
  account_send_password_forgot_already_auth_assigned: '296b27df-3d90-4c9c-93a0-546f06a6aaea',
  account_send_password_forgot_mail_failed: '10346650-2f99-4fab-bd8e-56f1ef9d9de4',
  account_send_password_forgot_mail_unknown_user: '576f6547-be51-43f4-855c-1d23b45d7892',
  account_send_password_forgot_not_allowed_for_master: 'd4b1bd59-f9e4-459c-bb43-97e878e16ba8',
  handle_azure_external_auth_disabled: '29a72552-f740-4ebf-95d9-02872449869f',
  handle_azure_login_failed: '08eeff20-4001-4e5f-b4b2-d39ee62ade62',
  handle_azure_tenant_error: '1d460bdc-fad9-4b70-a317-de959fd2a62e',
  start_oidc_process_failed: '7dd8fdbd-11f8-4d8b-9c67-ed80fad358d9',
  start_unknown_provider: '2d694c0e-d576-4c26-bda2-f7e641b884e7',
  unauthorized: '401',
  internal_server_error: '500',
};

config.authErrors = authErrors;
config.bridgeErrors = bridgeErrors;
config.errors = errors;
config.providers = providers;

export default config;
