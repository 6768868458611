import Vue from 'vue';
import VueBootstrap from 'bootstrap-vue';

// Custom plugins.
import HttpClient from './services/HttpClient';
import Logger from './services/Logger';
import './scss/app.scss';

import App from './App';
import config from './config';
import i18n from './i18n';
import router from './router';
import routes from './routes';
import store from './store';

// Iconset
import './icons';

Vue.config.productionTip = false;

Vue.use(VueBootstrap, {});

Vue.use(Logger, {
  enabled: !!process.env.VUE_APP_LOGGING_ENABLED,
});

Vue.prototype.$config = config;
Vue.prototype.$http = HttpClient;
Vue.prototype.$routes = routes;

new Vue({
  i18n,
  router,
  store,
  created() {
    const [locale] = (window.navigator.userLanguage || window.navigator.language).split('-');
    this.$i18n.locale = locale;
  },
  render: (h) => h(App),
}).$mount('#app');
