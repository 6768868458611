export default {
  account_assign: 'account/assign',
  account_authorize: 'account/authorize',
  account_create: 'account/create',
  email_send_verification_mail: 'email/sendVerificationMail',
  email_verify: 'email/verify',
  handle_azure: 'handle/azure',
  hello: 'hello',
  log: 'log',
  password_send_reset_mail: 'password/sendResetMail',
  password_reset: 'password/reset',
  start: 'start',
};
