import { render, staticRenderFns } from "./ipxx-checkbox.vue?vue&type=template&id=0d9d2b18&"
import script from "./ipxx-checkbox.vue?vue&type=script&lang=js&"
export * from "./ipxx-checkbox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports