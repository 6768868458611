import TestRoutes from './testRoutes';

const routes = [
  {
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
    name: 'login',
    path: '/login/:authProviderId',
  },
  {
    component: () => import(/* webpackChunkName: "login" */ '../views/Logout'),
    name: 'logout',
    path: '/logout/:authProviderId',
  },
  {
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword'),
    name: 'resetPassword',
    path: '/password/reset',
  },
  {
    component: () => import(/* webpackChunkName: "start" */ '../views/Start'),
    name: 'start',
    path: '/start/:authProviderId/:prompt?/:username?',
  },
  {
    component: () => import(/* webpackChunkName: "start" */ '../views/StartLogout'),
    name: 'startLogout',
    path: '/start-logout/:authProviderId',
  },
  {
    component: () => import(/* webpackChunkName: "verify" */ '../views/VerifyEmail'),
    name: 'verifyEmail',
    path: '/email/verify',
  },
  {
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound'),
    name: 'notFound',
    path: '*',
  },
];

if (process.env.NODE_ENV !== 'production') {
  // Load test routes only for development purposes.
  routes.push(...TestRoutes);
}

export default routes;
