import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

// Import solid icons.
import {
  faCheck, faCircle, faCircleNotch, faEnvelope, faGlobe, faPhone,
} from '@fortawesome/free-solid-svg-icons';

// Import regular icons.
import { faSquare } from '@fortawesome/free-regular-svg-icons';

// Import brand icons.
import {
  faFacebook, faFacebookF, faInstagram, faLinkedinIn, faTwitter,
} from '@fortawesome/free-brands-svg-icons';

// Import ipxx icons.
import IpxxBrand from './ipxx-icons/ipxx-brand';
import IpxxCheckbox from './ipxx-icons/ipxx-checkbox';

// Add solid icons to library.
library.add(faCheck);
library.add(faCircle);
library.add(faCircleNotch);
library.add(faEnvelope);
library.add(faFacebook);
library.add(faFacebookF);
library.add(faGlobe);
library.add(faInstagram);
library.add(faLinkedinIn);
library.add(faPhone);
library.add(faSquare);
library.add(faTwitter);

Vue.component('FaIcon', FontAwesomeIcon);
Vue.component('FaLayers', FontAwesomeLayers);
Vue.component('IpxxBrand', IpxxBrand);
Vue.component('IpxxCheckbox', IpxxCheckbox);
