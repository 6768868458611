import { $logger } from './Logger';

/**
 * Checks if the QWebChannel and qt are available as global variables.
 * @return {boolean}
 */
export const isQWebChannelActive = () => 'QWebChannel' in window && 'qt' in window;

class _MyPmBridge {
  /**
   * MyPmBridge constructor.
   */
  constructor() {
    this.bridge = null;
    this.isInitialized = false;
  }

  /**
   * Initializes the bridge if possible.
   */
  init() {
    if (!this.isInitialized) {
      // Only log info once.
      const logInfo = `Init bridge. Is qt in window? ${'qt' in window ? 'true' : 'false'} Is QWebChannel in window? ${'QWebChannel' in window ? 'true' : 'false'}`;
      $logger.log(logInfo);
    }
    return new Promise((resolve) => {
      if (!this.isInitialized && isQWebChannelActive()) {
        // eslint-disable-next-line no-new
        new QWebChannel(qt.webChannelTransport,
          (channel) => {
            $logger.log('Channel callback.');
            if (channel.objects && channel.objects.mypm && channel.objects.mypm.ipxx) {
              $logger.log('Ipxx object found.');
              this.bridge = channel.objects.mypm.ipxx;
              this.isInitialized = true;
            }
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  /**
   * Calls the my-pm function 'setTokens'.
   * @param {string} accessToken
   * @param {string} authToken
   * @return {Promise}
   */
  async callSetTokens(accessToken, authToken) {
    await this.init();
    $logger.info('Call set tokens.');
    if (!this.bridge) {
      $logger.info('No bridge found, function setTokens is not executed.');
      // no bridge found, so nothing happens.
      return true;
    }
    if (this.isFunctionAvailable('setTokens')) {
      this.bridge.setTokens(accessToken, authToken);
      return true;
    }
    return false;
  }

  /**
   * Calls the my-pm function `userLogoutFinished`.
   * @return {Promise}
   */
  async callLogoutFinished() {
    await this.init();
    $logger.info('Call finish logout');
    if (!this.bridge) {
      $logger.info('No bridge found, function logoutFinished is not executed.');
      // no bridge found, so nothing happens.
      return true;
    }
    if (this.isFunctionAvailable('userLogoutFinished')) {
      this.bridge.userLogoutFinished();
      return true;
    }
    return false;
  }

  /**
   * Checks if the given function is available by the bridge.
   * @param functionName
   * @return {boolean}
   */
  isFunctionAvailable(functionName) {
    if (!this.bridge[functionName]) {
      $logger.error('Called function is not available:', functionName);
      return false;
    }
    if (!(this.bridge[functionName] instanceof Function)) {
      $logger.error('Tried to call non-function:', functionName);
      return false;
    }
    return true;
  }
}

// Create an instance to use only one MyPmBridge instance.
const $myPmBridge = new _MyPmBridge();

export default $myPmBridge;
