import axios from 'axios';
import i18n from '../i18n';

const intstance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  delayed: false,
});

// Global interceptor add an artificial delay on requests per default.
intstance.interceptors.request.use((config) => {
  config.headers.common['Accept-Language'] = i18n.locale;
  if (config.delayed) {
    return new Promise((resolve) => setTimeout(() => resolve(config), 600));
  }
  return config;
});

// Transform responses and reject requests on failed backend status.
intstance.interceptors.response.use((response) => {
  const { data } = response;
  if (data.status !== 'success') {
    return Promise.reject(data);
  }
  return data;
}, (error) => Promise.reject(error));

export default intstance;
