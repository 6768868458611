<template>
  <fa-layers
    data-cy="ipxxBrandIcon"
  >
    <fa-icon
      class="text-ipxx-medium"
      :icon="['fas', 'circle']"
      transform="grow-12"
    />
    <fa-icon
      class="text-ipxx-lightest"
      :icon="['fab', iconName ]"
    />
  </fa-layers>
</template>

<script>
export default {
  name: 'IpxxBrand',
  props: {
    /**
     * The fa icon name.
     */
    iconName: {
      required: true,
      type: String,
    },
  },
};
</script>
